.login_form {
  h1 {
    color: var(--secondary-text);
  }
  // padding-left: 15rem;
  // padding-right: 15rem;
 
  .p-divider.p-divider-horizontal{
    &::before{
      border-top: 1px solid #333333;
    }

    .p-divider-content > p{
      font-size: 16px;
      color: #333333;
      letter-spacing: 1.2px;
    }
  }

}

.for_pass {
  label {
    font-size: 16px !important;
  }
  p {
    font-size: 16px;
    font-weight: 400;
  }
}

.otp_input {
  width: 48px !important;
  height: 48px !important;
}

.otp-separator {
  display: none;
}

.otp-separator:nth-child(6) {
  display: block !important;
  margin-bottom: 10px;
}

.otp_time {
  font-size: 16px !important;
}

.border-success {
  border-color: #089a43;
}

.border-success:hover {
  border-color: #089a43 !important;
}

.border-danger {
  border-color: #ff3030;
}
.border-danger:hover {
  border-color: #ff3030 !important;
}

//Main check box styling for login page
.p-checkbox .p-checkbox-box[data-p-highlight="true"] {
  background: var(--primary-color);
  border: 2px solid var(--primary-color);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  background: var(--primary-color);
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
}
.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--primary-color);
}

.p-checkbox {
  border-color: transparent;
}
