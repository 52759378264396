.viv_dialog {
    border-radius: 24px;

    .p-dialog-header {
        border-top-right-radius: 15px;
        border-top-left-radius: 15px;

        .p-dialog-header-icon {
            color: var(--secondary-black) !important;

            &:hover {
                background: #F1F6FC !important;
            }

            &:focus {
                box-shadow: none;
            }
        }

        .p-dialog-title {
            font-weight: 600 !important;
        }
    }

    .p-dialog-footer {
        border-top: 1px solid #D6E4F7;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .p-dialog-content {
        padding: 0 3rem 3.5rem 3.5rem;
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
    }

    .p-button-text {
        background-color: #F1F6FC;
        border: 1px solid #D6E4F7;
        color: var(--secondary-black) !important;

        &:hover {
            background-color: #F1F6FC !important;
            border: 1px solid #D6E4F7 !important;
        }
    }
}

.vivconfirm_popup {
    border-radius: 24px;

    .p-dialog-header {
        border-top-right-radius: 24px;
        border-top-left-radius: 24px;
        padding: 1.5rem 1.5rem 0.1rem 1.5rem !important;

        .p-dialog-header-icon {
            color: var(--secondary-black) !important;
            background: #F1F6FC !important;


            &:focus {
                box-shadow: none;
            }
        }

        .p-dialog-title {
            font-weight: 600 !important;
        }
    }

    .p-dialog-footer {
        border-top: 1px solid #D6E4F7;
        border-bottom-right-radius: 24px;
        border-bottom-left-radius: 24px;
        background-color: #F8FAFD;
        padding: 0.75rem 0.75rem 0.75rem 1.5rem;
    }

    .p-dialog-content {
        padding: 0 3rem 1rem 3.5rem !important;
    }

    .p-button-text {
        background-color: #F1F6FC;
        border: 1px solid #D6E4F7;
        color: var(--secondary-black) !important;

        &:hover {
            background-color: #F1F6FC !important;
            border: 1px solid #D6E4F7 !important;
        }
    }

    .p-confirm-dialog-icon {
        i {
            font-size: 32px !important;
            color: #0097FE;
            background-color: #EBF4FF;
            padding: 7px 13px 18px 13px;
            border-radius: 50px;
        }
    }

    .p-confirm-dialog-message {
        font-weight: 500;
        font-size: 16px;
        color: var(--secondary-black);
    }
}

.historydialog {
    border-radius: 24px;

    .p-dialog-header {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;

        .p-dialog-header-icon {
            background: #F1F6FC;
        }

    }

    .p-dialog-footer {
        padding: 0.5rem 0.5rem 0.5rem 0.5rem;
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;
    }
}

.sign_up_dialog {
    .p-dialog-title {
        font-weight: 500 !important;
        font-size: 1.5rem !important;
    }

}

.termsConditions {
    padding: 30px 20px 0 20px;
    .item{
        font-family: Poppins;
        font-size: 16px;
        font-weight: 500;
        line-height: 15.96px;
        text-align: left;
    }
    .sub{
        font-family: Poppins;
        font-size: 14px;
        font-weight: 500;
        line-height: 15.96px;
        text-align: left;
    }
    p{
        font-family: Poppins;
        font-size: 12px;
        font-weight: 400;
        line-height: 15.96px;
        text-align: left;
    }
}

.newuser-dialog{
    width: 35%;
}