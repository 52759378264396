.viv_header {
  border-bottom: 1px solid #d6e4f7;
  padding: 6px 20px 1px 20px;
  background: #ffffff !important;
  height: 65px;
  .search {
    .p-dropdown {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      background: #f8fafd;

      &.p-focus {
        box-shadow: none;
        border-color: #ced4da;
      }

      .p-inputtext {
        padding: 8px 8px;
      }
    }
  }

  .p-dropdown-trigger {
    width: 2rem;
  }

  .header_searchBar {
    padding: 8px 12px;
    border-top-left-radius: 0px;
    border-left: 0px;
    border-bottom-left-radius: 0px;
    color: var(--secondary-black);

    &:focus {
      box-shadow: none;
    }

    &:hover {
      border-color: #ced4da;
    }
  }

  .user_info {
    .p-dropdown-label.p-placeholder {
      color: var(--secondary-black);
    }

    p {
      font-size: 14px;
    }

    #user_role {
      font-weight: 400;
    }

    #user {
      font-weight: 500;
    }

    .i-bell {
      font-size: 25px !important;
    }

    .p-badge {
    background: #fe4215;
    border: 1px solid #fe4215;
    min-width: 14px;
    height: 14px;
    font-size: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    }

    .user_optionMenu {
      background: none;
      height: 1rem;
      width: 1rem;
      padding: 10px;
      color: var(--secondary-black) !important;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        background: none !important;
        color: var(--secondary-black) !important;
      }

      .p-button-icon {
        font-size: 8px;
      }
    }
  }

  .p-dropdown-trigger {
    width: 2rem;
  }

  .p-dropdown-label.p-placeholder {
    color: var(--secondary-black);
  }

  .p-dropdown-panel {
    top: 55px !important;
    min-width: 127.0781px !important;
  }

  .p-dropdown-items-wrapper {
    font-size: 14px;
    min-height: 200px;
    max-height: 350px !important;
  }

  .mob-menuBtn{
    width: 3rem !important;
    height: 3rem !important;
        z-index: -1;
    background: radial-gradient(circle at 49% 55%, rgb(0, 151, 254) 0%, rgb(32, 84, 217) 0%, rgb(32, 84, 217) 25%, rgb(0, 151, 254) 100%, rgb(0, 151, 254) 100%) !important;
    &.mobiledevice{
      position: absolute;
      left: 21px;
    }
    &:hover{
      background: radial-gradient(circle at 49% 55%, rgb(0, 151, 254) 0%, rgb(32, 84, 217) 0%, rgb(32, 84, 217) 25%, rgb(0, 151, 254) 100%, rgb(0, 151, 254) 100%) !important;
    }
    .p-button-icon{
      font-size: 18px !important;
    }
  }
}

.viv_pageHeader {
  .page_title > H3 {
    font-weight: 400;
    font-size: 22px;
    color: var(--secondary-black);
    line-height: 22px;
  }

  p {
    color: var(--secondary-black);
    font-weight: 500;
    font-size: 18px;
  }
}

// .p-dropdown-panel {
//   width: 110px !important;
//   margin-top: 8px;
// }
.p-dropdown-items-wrapper {
  max-height: 200px !important;
}

.topbar-title{
  font-size: 14px !important;
  align-self: center !important;
  margin: 0 !important;
  font-weight:500 !important;
  line-height: 24px !important;
  font-family: "Poppins", sans-serif;
}

.p-dropdown-header{
  padding: 0 !important;
  background-color: white !important;
}
.p-dropdown-filter-container>input{
  border-radius: 0;
  width: 100% !important;
  border: none;
  background-color: white !important;
}
.profile-menu {
  padding: 0;
  min-width: 200px;
  border-radius: 500px;
}
.profile-section {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.profile-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.top-bar-img{
  min-width: 50px;
  min-height: 50px;
  background-image: url("../../assets/images/profile-pic.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.profile-details {
  margin-left: 1rem;
}
.profile-name {
  font-weight: 500;
  font-size: 1rem;
  font-family: "Poppins", sans-serif;
}
.profile-email {
  /* color: #666; */
  color: black;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
}
.menu-items {
  margin-top: 1rem;
}
.menu-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0.1rem 0;
  font-size: 1rem;
  color: #333;
}
.menu-item:hover {
  color: #fe4215;
}



.active-location{
  //background-color: #EEF2FF;
  color: #fe4215;
  &:active,
  &:hover {
    background: rgb(235, 235, 235);
  }
}




@media (max-width: 768px) {

  .overlay-panel-custom {
    min-width: 250px;
  
  
    
  }

  .p-overlaypanel {
    &:before {
        content:none !important;
    }
  
    &:after {
        content:none !important;
    }
  
    margin-top: 0px; 
  }


}




