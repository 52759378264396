.viv_login-bg {
  //background-color: #1f56da;
  background-image: url("../../public/images/bgpic.png");
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.viv_login-bg p {
  font-size: 36px;
  color: #fff;
  line-height: 47px;
  font-weight: 500;
}


//filter css
.p-dropdown, .p-multiselect {
  height: 38px;
}
.p-dropdown-items .p-dropdown-item {
  padding: 0.5rem 0.75rem !important;
  font-size: 12px;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: var(--secondary-black);
}
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: #fff;
}
.p-dropdown .p-dropdown-trigger {
  color: var(--secondary-black);
}
::placeholder {
  color: var(--secondary-black);
}
//Multi select
.p-multiselect.p-multiselect-chip .p-multiselect-token {
  padding: 5px 5px;
  background: #ffe1cc;
  color: #000000;
  border-radius: 5px;

  .p-multiselect-token-label {
    font-size: 11px;
    font-family: "Poppins";
    font-weight: 500;
  }
}

.p-multiselect-panel .p-multiselect-header,
.p-dropdown-panel .p-dropdown-header {
  // display: none;
  flex-direction: column-reverse;
  padding: 0rem 0rem;
  background: transparent;
  border: 0px;
  .p-multiselect-filter-container,
  .p-dropdown-filter-container {
    width: 100%;
    margin-bottom: 11px;

    .p-multiselect-filter,
    .p-dropdown-filter {
      border: 0px;
      border-bottom: 1px solid #d6e4f7;
      border-radius: 0;
    }
  }

  .p-multiselect-select-all {
    width: 100%;
    padding-left: 9px;
    margin-bottom: 3px;

    .p-checkbox::after {
      content: "Select All";
      position: absolute;
      left: 29px;
      width: 72px;
      font-size: 12px;
      font-family: "Poppins";
      color: #000000;
    }
  }

  .p-multiselect-close {
    display: none;
  }
}

.p-multiselect-panel {
  &.hide-selectall {
    .p-multiselect-select-all {
      display: none;
    }
  }
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0px;
}

.p-multiselect:not(.p-disabled):hover {
  border-color: var(--primary-input);
}

.p-multiselect:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  box-shadow: none;
  border-color: var(--primary-input);
}

.p-multiselect:not(.p-disabled).p-focus {
  border-color: var(--primary-input);
}

.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover {
  background: rgb(235, 235, 235);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  color: #000000;
  font-size: 12px;
  padding: 5px 9px;
  align-items: flex-start;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #fe4215;
  background: none !important;
}
.p-multiselect-panel
  .p-multiselect-items
  .p-multiselect-item.p-highlight:hover {
  color: #fe4215;
  background: rgb(235, 235, 235) !important;
}
.p-multiselect {
  border-color: var(--primary-input);
}
.p-multiselect .p-multiselect-label.p-placeholder {
  color: var(--secondary-black);
}
.p-multiselect .p-multiselect-trigger {
  color: var(--secondary-black);
}
.p-multiselect-item {
  font-size: 14px;
}

.p-checkbox .p-checkbox-box {
  height: 17px;
  width: 17px;
  border-radius: 2px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
  width: 11px;
  height: 10px;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover,
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: var(--primary-color);
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  width: 11px;
  height: 10px;
  padding-left: 0px;
}

.grid_dialog {
  border-radius: 24px !important;
  .reset {
    background: #f1f6fc;
    color: var(--secondary-text);
    border: 1px solid #d6e4f7;
  }

  .reset:enabled:active,
  .reset:enabled:active,
  .reset:enabled:hover {
    background: #f1f6fc;
    color: var(--secondary-text);
    border: 1px solid #d6e4f7;
  }
  .p-component {
    font-size: 16px;
  }
  .save_filter {
    border-radius: 8px;
    color: #293752;
    .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
      padding: 0.5rem 0.75rem !important;
    }
    .p-inputtext {
      padding: 0.5rem 0.75rem;
      font-size: 14px;
    }
  }
  .p-dialog-header {
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
  }
  .p-dialog-header .p-dialog-title {
    font-weight: 500;
    font-size: 22px;
  }
  .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: #f1f6fc;
  }
  .p-dialog-header .p-dialog-header-icon {
    background: #f1f6fc;
    color: #293752;
  }
  .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none;
  }
  .p-dialog-footer {
    background: #f8fafd;
    border-top: 1px solid #d6e4f7;
    padding: 0.5rem 1.5rem 0.5rem 1.5rem;
    border-bottom-right-radius: 24px;
    border-bottom-left-radius: 24px;
  }
}

.p-toast {
  max-width: 21rem !important;
  z-index: 99999999999 !important;
}

.p-toast .p-toast-message.p-toast-message-success {
  border: 1px solid #c7e9d0;
  background: #ebf7ee;
  border-radius: 7px;
}

.p-toast .p-toast-message {
  box-shadow: none;
}
.p-toast .p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #a4a4a4;
  font-weight: 700;
  align-items: flex-start;
}

.p-toast-message-content {
  align-items: center !important;
}

.p-toast .p-toast-message .p-toast-icon-close:focus {
  box-shadow: none;
}
.p-toast .p-toast-message .p-toast-icon-close:hover {
  background: transparent;
}

.p-toast .p-toast-message .p-toast-message-content .p-toast-message-text {
  color: var(--secondary-black);
}

.p-toast .p-toast-message.p-toast-message-success .p-toast-message-icon {
  color: #fff;
}

.p-toast-message-text {
  text-align: center;
  margin: 0px;
}

.p-toast {
  opacity: 1;
}

.p-toast .p-toast-message.p-toast-message-error {
  border: 1px solid #f7ccc7;
  background: #fcedea;
  border-radius: 7px;
}

.p-toast .p-toast-message.p-toast-message-error .p-toast-icon-close {
  color: #a4a4a4;
  font-weight: 700;
  align-items: flex-start;
}
.p-toast .p-toast-message .p-toast-message-content .p-toast-summary {
  font-weight: 400;
  color: #2f2f2f;
  font-size: 12px;
}

// UI for account switch 
.switch-account{
  display: flex;
  align-items: center;
  border: 1px solid #D0D5DD;
  border-radius: 20px;
  padding: 4px;
  gap: 7px;
}
.active-account{
  border: 1px solid #fe4215;
  background: #fe4215;
  border-radius: 456.44px;
  padding: 10px 20px;
  color: #FFFFFF;
}
.inactive-account{
    color: #fe4215;
    cursor: pointer;
}
