//overlay css
/* .notification-panel {
  position: absolute;
  margin-top: 10px;
  left: calc(100vw - 380px) !important;
  right: 20px !important;
  border-radius: 20px;
  top: 75px !important;
  height: 500px;

  .p-overlaypanel-content {
    padding: 15px 10px;
  }

  .p-overlaypanel-close:enabled:hover {
    background: #f1f6fc;
    color: var(--secondary-black);
  }
  .p-overlaypanel-close {
    background: #f1f6fc;
    color: var(--secondary-black);
    position: absolute;
    right: 20px;
    //   width: 1.25rem;
    //   height: 1.25rem;
    top: 10px;
  }
  .p-link:focus {
    box-shadow: none;
  }

  &:before {
    content: none;
  }

  &:after {
    content: none;
  }
  margin-top: 0px;

  .read-msgs {
    i {
      font-size: 12px;
    }
    p {
      font-size: 14px;
    }
  }

  .noti-text {
    padding-left: 10px;
    p,
    span {
      font-size: 12px;
      color: var(--secondary-black);
      font-weight: 600;
    }
    span {
      display: inline-block;
      text-align: start;
      width: 300px;
      white-space: nowrap;
      overflow: hidden !important;
      text-overflow: ellipsis;
      padding-right: 30px;
    }
    small {
      color: #8a8a8a;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .noti-early {
    border: 1px solid #d6e4f7;
    padding: 5px;
    border-radius: 10px;
  }
  .noti-new {
    border: 1px solid transparent;
    padding: 5px;
    border-radius: 10px;
    background-color: #f8fafd;
  }

  .noti-icon {
    height: 30px;
  }

  .noti-sub-heading {
    font-size: 14px;
    font-weight: 600;
    color: var(--secondary-black);
  }

  .footer {
    position: absolute;
    bottom: 17px;
    left: 45%;
  }
  a {
    font-size: 12px;
    color: var(--secondary-black);
  }

  .p-overlaypanel-content::after {
    content: "";
    width: 100%;
    height: 2px;
    left: 0;
    bottom: 45px;
    position: absolute;
    background: #d6e4f7;
  }

  .p-icon {
    width: 0.75rem;
    height: 0.75rem;
  }
}

.notification-dialog {
  //max-width: 400px;
  width: 506px !important;
  border-radius: 20px;
  .p-dialog-content {
    flex-direction: column;
    gap: 15px;
    padding: 0 5rem 2rem 5rem;
    text-align: center;
  }

  .p-dialog-footer {
    padding: 0.5rem 0.5rem;
    background: #f8fafd;
    border: 1px solid #d6e4f7;
  }

  .p-dialog-header {
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    padding: 1rem 1rem 0rem 1rem !important;
  }
  .p-dialog-footer {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .p-confirm-dialog-icon {
    background-color: #ebf4ff;
    border-radius: 50px;
    width: 74px;
    height: 75px;
    padding-top: 17px;
  }

  .p-button.p-button-text {
    background: #d6e4f7;
    color: var(--secondary-black);
  }
  .confirm-box-link {
    color: #0097fe;
  }

  .p-confirm-dialog-message {
    font-weight: 500;
    color: var(--secondary-black);
  }

  .p-dialog-header .p-dialog-header-icon {
    background: #f1f6fc;
    color: var(--secondary-black);
  }

  .p-dialog-header .p-dialog-header-icon:enabled:hover {
    background: #f1f6fc;
  }

  .p-dialog-header .p-dialog-header-icon:focus {
    box-shadow: none;
  }

  .p-button.p-button-text:enabled:hover {
    background: #d6e4f7;
    color: var(--secondary-black);
  }
  .p-button {
    padding: 0.5rem 1rem;
  }

  .p-dialog-mask.p-component-overlay {
    pointer-events: auto;
    z-index: 8000 !important;
  }

  .p-dialog-header .p-dialog-title {
    font-weight: 600;
    font-size: 16px;
  }
}

///detail page css
.noti-main-heading{
  font-size: 20px;
  font-weight: 600;
  color: var(--secondary-black);
}

.detail-noti-new {
  border: 1px solid transparent;
  padding: 5px;
  border-radius: 10px;
  background-color: #F1F6FC;
}
.detail-noti-text{
  padding-left: 10px;
  p{
    font-size: 14px;
    color: var(--secondary-black);
    font-weight: 600;
  
  }
  span.username {
    font-size: 14px;
    color: var(--secondary-black);
    font-weight: 600; 
  }
  span.description {
    font-size: 12px; 
    color: #8A8A8A;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-right: 30px;
    display: inline-block;
    text-align: start;
    width: 800px;
  }

  span {
    font-size: 12px;
    color: var(--secondary-black);
    font-weight: 600;
  }
  span {
    display: inline-block;
    text-align: start;
    width: 120px;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    padding-right: 30px;
    color: #8A8A8A;
  }
  small {
    color: #8a8a8a;
    font-size: 12px;
    font-weight: 600;
  }
}


.no-from{
  .content-section{
      height: auto;
  }

  .form_wrapper{
      height: 40vh;
      overflow: auto;
  }
}

.custom-dropdown {
  cursor: pointer;
  font-size: 1.2rem;
  user-select: none;
  border: none;
  background-color: transparent;
  
 .p-dropdown-trigger .p-dropdown-trigger-icon {
    display: none; 
  }
} */




//notification sidebar



.notification-panel {
  width: 388px !important;
  top: 75px !important;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  overflow: hidden;
  
  .p-sidebar-header{
    padding: 0.75rem !important;
  }
  
  .new-notifications{
    background-color: #D7E7F44D;
  }
  
  .noti-static{
    padding: 0 1.25rem;
  }
  
  .highlight{
    color: #fe4215;
    font-size: 10px;
    font-weight: 500;
  }
  
  .early-notifications{
        background-color: #FCFCFC;
  }
  
  .p-sidebar-content{
    padding: 0;
  }
  
  .notification-header{
    font-size: 15px;
    color: #fe4215;
    font-weight: 500;
    padding: 0 1.25rem;
    margin: 10px 0 10px 0;
  }
  
  i{
    font-size: 14px;
  }
  
    .footer{
    margin: 1em 0 1em 0;
    
    a{
      color: #000000;
      text-decoration: underline;
      font-weight: 500;
      font-size: 12px;
    }
  }
  
  .p-divider.p-divider-horizontal {
    margin: 1.25rem 0 0 0;
    padding: 0 1.25rem;
}
}

.noti-header-cont{
  border-bottom: 1px solid #E4E7EB;
  padding: 0 1.25rem 0.5rem 1.25rem;
  
  i{
    color: #000000;
    font-size: 12px;
  }

}

.new-notifications h1{
  font-size: 13px !important;
}


.noti-h3{
  font-size: 15px !important;
  font-weight: 500 !important;
  line-height: 24px !important;
  font-family: "Poppins", sans-serif !important;
  color: #222529 !important;
}



.p-sidebar-close{
  
  display: none !important;
}


.noti-section{
  max-height: 82dvh;
  min-height: 82dvh;
  overflow-x: hidden !important;
  max-width: 100%;
}

.noti-static{
  .p-button{
    min-width: 100px;
    max-width: 110px ;
    height: 30px;
    &:hover{
      height: 30px;
      max-width: 110px;
    }
  }
}

.noti-static h1{
  font-size: 13px;
  color: #222529;
  margin: 10px 0;
}



.noti-static-msg p{
    font-size: 10px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
    line-height: 18px;
    color: #5A6474;
    width: 257px;
    white-space: pre-wrap;
    text-overflow: ellipsis;

}

.noti-static-msg span{
  font-size: 9px;
  font-weight: 400;
  line-height: 16px;
  color: #5A6474;
}

.noti-time-frame-cont{
  width: 188px;
}

.noti-time-frame-msg span{
  font-size: 10px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
  color: #333333;
}




